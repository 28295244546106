import { useReducer } from "react";
import { Dashboard } from "./components/Dashboard";
import { data } from "./data/data";
import { DataContext } from "./data/DataContext";
import { dataReducer } from "./data/dataReducer";

const init = () => {
  return data;
}

const App = () => {

  const [data, dispatch] = useReducer(dataReducer, {}, init);

  return (
    <DataContext.Provider value={{data, dispatch}}>
      <Dashboard />
    </DataContext.Provider>
  );
}

export default App;
