import React from 'react'

export const AboutScreen = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 text-center my-4 bg-white rounded rounded-pill">
                    <h1 className="display-3 fw-bold text-gray-cyan  my-0 py-0 text-shadow">About us!</h1>
                </div>
                <div className="col-12 mb-5">
                    <div className="container bg-comic">
                        <div className="row py-5">
                            <div className="col-12">
                                <div className="row row-cols-1 row-cols-md-2 g-4">
                                    <div className="col">
                                        <div className="card h-100 mb-3">
                                            <div className="row g-0">
                                                <div className="col-md-4">
                                                    <div>
                                                        <img src="./assets/images/1_CleanAGS.webp" className="img-thumbnail blurryImg" alt="clean robot" 
                                                        width="100%"
                                                        height="100%" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">CleanAGS</h5>
                                                        <p className="card-text">We know that a clean workspace, increases employee productivity and improves workplace health and safety. That is why <b>CleanAGS</b> strives to continually provide a superior cleaning experience with competitive pricing. Working with a diverse clientele, we provide a service plan that is tailored to the specific needs of each individual company.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card h-100 mb-3">
                                            <div className="row g-0">
                                                <div className="col-md-4">
                                                    <img src="./assets/images/2_Our_Team.webp" className="img-thumbnail blurryImg" alt="clean team"
                                                    width="100%"
                                                    height="100%" />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Our team!</h5>
                                                        <p className="card-text"><b>CleanAGS</b> is fully insured and our employees are  extensively trained in commercial cleaning techniques and requirements. Let our reliable team do the dirty  work and help your business shine.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col">
                                        <div className="card h-100 mb-3">
                                            <div className="row g-0">
                                                <div className="col-md-4">
                                                    <img src="./assets/images/PopArt-04.jpg" className="img-thumbnail" alt="ask an expert" />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Expertise</h5>
                                                        <p className="card-text">We are a company determined to offer the best quality and experience in cleaning offices, warehouses, parking lots, supermarkets, all kinds of commercial cleaning, scrubbing, deep cleaning, and more... Services starting at $ 0.15 cents per square foot.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
