import React, { useEffect, useState } from 'react';

export const Carousel = () => {

    const [height, setHeight] = useState("");

    useEffect(() => {
        if (window.innerHeight < window.innerWidth) {
            setHeight("height90vh");
        } else {
            setHeight("height40vh");
        }
    }, [])

    return (
        <div id="carouselCleanAGS" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {/* <button type="button" data-bs-target="#carouselCleanAGS" data-bs-slide-to="0" className="active"></button> */}
                {/* <button type="button" data-bs-target="#carouselCleanAGS" data-bs-slide-to="1"></button> */}
            </div>
            <div className="carousel-inner">
                <div className={`carousel-item active ${height}`}>
                    <img src="./assets/images/Complementos-01.webp" className="d-block w-100 h-100" alt="clean" fetchpriority="high" />
                    <div className="carousel-caption d-none d-md-block">
                        <h1 className="display-1 fw-bold text-shadow">Janitorial Service</h1>
                        <p className="fs-1 text-shadow">Shine with our offers!</p>
                    </div>
                </div>
                {/* <div className={`carousel-item ${height}`}>
                    <img src="./assets/images/Complementos-01.jpg" className="d-block w-100 h-100" alt="clean" />
                    <div className="carousel-caption d-none d-md-block">
                        <h1 className="display-1 fw-bold text-shadow">Cleaning Service</h1>
                        <p className="fs-1 text-shadow" >100% GUARANTEE</p>
                    </div>
                </div> */}
            </div>
            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselCleanAGS" data-bs-slide="prev">
                <span className="carousel-control-prev-icon"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselCleanAGS" data-bs-slide="next">
                <span className="carousel-control-next-icon"></span>
                <span className="visually-hidden">Next</span>
            </button> */}
        </div>
    )
}
