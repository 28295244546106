import { returnServicesServiceActiveCardInfo, returnServicesRandomServiceActiveCardInfo } from "../helpers/helpers";
import { types } from "../types/types";


export const dataReducer = (state = {}, action) => {

    let newState;

    switch (action.type) {

        case types.click:
            newState = returnServicesServiceActiveCardInfo(state, action.payload);
            return {
                ...state,
                services: newState.services,
                cardInfo: newState.cardInfo
            };

        case types.random:
            newState = returnServicesRandomServiceActiveCardInfo(state);
            return {
                ...state,
                services: newState.services,
                cardInfo: newState.cardInfo
            }

        default:
            return state;
    }

}