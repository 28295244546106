import React from 'react';
import { data } from '../../../data/data';
// import { WorkModal } from './WorkModal';
import { WorksList } from './WorksList';

export const WorksScreen = () => {

    const { works } = data;

    return (
        <div className="container">
            {/* <WorkModal /> */}
            <div className="row">
                <div className="col-12 text-center my-4 bg-white rounded rounded-pill">
                    <h1 className="display-3 fw-bold text-gray-cyan text-shadow my-0 py-0">Our work</h1>
                </div>
                <div className="col-12 mb-5">
                    <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-4">
                        <WorksList works={works} />
                    </div>
                </div>
            </div>
        </div>
    )
}
