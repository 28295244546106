import React from 'react';
import { WorkItem } from './WorkItem';

export const WorksList = ({ works }) => {
    return (
        <>
            {
                works.map(work => (
                    <div key={work.id}>
                        <WorkItem work={work} />
                    </div>
                ))
            }
        </>
    )
}
