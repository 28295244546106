import React from 'react';
import { Navbar } from './ui/Navbar';
import { Carousel } from './ui/Carousel';
import { ServicesScreen } from './sections/services/ServicesScreen';
import { WorksScreen } from './sections/works/WorksScreen';
import { ContactScreen } from './sections/contact/ContactScreen';
// import { FaqScreen } from './sections/faq/FaqScreen';
// import { UnderConstructionScreen } from './ui/UnderConstructionScreen';
import { Footer } from './ui/Footer';
import { AboutScreen } from './sections/about/AboutScreen';

export const Dashboard = () => {
    return (
        <div className="container-fluid mx-0 px-0">
            <Navbar />
            <div className="mx-0">
                <Carousel />
                <div id="aboutUsScreen"></div>
            </div>
            <div>
                <AboutScreen />
                <div id="servicesScreen"></div>
            </div>
            <div className="mx-0 bg-white">
                <ServicesScreen />
                <div id="worksScreen"></div>
            </div>
            <div className="mx-0">
                <WorksScreen />
                <div id="contactScreen"></div>
            </div>
            <div className="bg-white mb-5">
                <ContactScreen />
                <div id="faqScreen"></div>
            </div>
            {/* <div>
                <FaqScreen />
            </div> */}
            <Footer />
            {/* <div className="mx-0" style={{ backgroundColor: "rgb(138 1 0)" }}>
                <UnderConstructionScreen />
            </div> */}
        </div>
    );
}
