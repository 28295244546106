import React from 'react';
import { MenuItem } from './MenuItem';

export const ServicesMenuList = ({ services }) => {
    return (
        <>
            {services.map((service) => (
                <div key={service.id}>
                    <MenuItem {...service} />
                </div>
            ))}
        </>
    )
}
