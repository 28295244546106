import React from 'react';
import { Phone } from 'react-feather';

export const Navbar = () => {

    const scrollToElement = (elementId) => {
        if (elementId === "carouselCleanAGS") {
            // element which needs to be scrolled to
            const element = document.getElementById(elementId);
            // scroll to element
            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "end" });
        } else {
            // element which needs to be scrolled to
            const element = document.getElementById(elementId);
            // scroll to element
            element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
        }
    }

    return (
        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light boxShadowMenu">
            <div className="container-fluid">
                <span className="navbar-brand pointer" onClick={() => scrollToElement("carouselCleanAGS")}>
                    <img src="./assets/images/CleanAGSLogotipoOri.png" alt="cleanAGS Logo" width="250" height="60" className="d-inline-block align-text-top" />
                </span>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item pointer" onClick={() => scrollToElement("aboutUsScreen")}>
                            <span className="nav-link">About us</span>
                        </li>
                        <li className="nav-item pointer" onClick={() => scrollToElement("servicesScreen")}>
                            <span className="nav-link">Services</span>
                        </li>
                        <li className="nav-item pointer" onClick={() => scrollToElement("worksScreen")}>
                            <span className="nav-link">Our work</span>
                        </li>
                        <li className="nav-item pointer" onClick={() => scrollToElement("contactScreen")}>
                            <span className="nav-link">Contact</span>
                        </li>
                        {/* <li className="nav-item pointer" onClick={() => scrollToElement("faqScreen")}>
                            <span className="nav-link">FAQ</span>
                        </li> */}
                    </ul>
                </div>
                <span><Phone color="black" size={16} /> (437) 771-7885</span>
            </div>
        </nav>
    )
}
