import React from 'react';

export const WorkItem = ({ work }) => {

    return (
        <div className="col" data-bs-toggle="modal" data-bs-target="#cleanAGSModal">
            <div className="card bg-dark text-white boxShadowCard">
                <img src={`./assets/images/works/${work.img}.webp`} className="card-img" alt="CleanAGSWorks" />
                <div className="card-img-overlay hidden animate__animated animate__zoomInDown animate__faster">
                    <div className="d-flex align-items-end">
                    <div className="text-center d-none d-md-block bg-dark rounded-pill px-2">{work.miniDesc}</div>

                    </div>
                </div>
            </div>
        </div>
    )
}
