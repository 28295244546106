import React from 'react';
import { useForm } from '../../../hooks/useForm';

export const FormComponent = () => {

    const initialForm = {
        name: '',
        email: '',
        message: ''
    };

    const [formValues, handleInputChange, reset] = useForm(initialForm);

    const { name, email, message } = formValues;

    const handleClickSubmit = (e) => {
        e.preventDefault();
        const buttonMailTo = document.getElementById("sendEmail");
        buttonMailTo.setAttribute('href', `mailto:cleaningags@gmail.com?subject=nombre ${name}  correo ${email}&body=${message}`);
        buttonMailTo.click();
        reset();
    }
    return (
        <div>
            <h1 className="display-5 fw-bold text-shadow">SEND US A MESSAGE!</h1>
            <form id="form" onSubmit={handleClickSubmit}>
                <div className="mb-3">
                    <label htmlFor="InputName" className="form-label fw-bold">NAME:</label>
                    <div className="form-floating">
                        <input type="text" name="name" value={name} onChange={handleInputChange} className="form-control border border-dark border-4 rounded-0" placeholder="John Hills" />
                        <label className="text-dark" htmlFor="InputName">Name...</label>
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="InputEmail" className="form-label fw-bold">EMAIL:</label>
                    <div className="form-floating">
                        <input type="email" name="email" value={email} onChange={handleInputChange} className="form-control border border-dark border-4 rounded-0" aria-describedby="emailHelp" placeholder="john@gmail.com" />
                        <label className="text-dark" htmlFor="InputEmail">Email...</label>
                    </div>
                    <div id="emailHelp" className="form-text text-white">We'll never share your email with anyone else.</div>
                </div>
                <div className="mb-3">
                    <label htmlFor="InputTextarea" className="form-label fw-bold">MESSAGE:</label>
                    <div className="form-floating">
                        <textarea className="form-control border border-dark border-4 rounded-0" name="message" value={message} onChange={handleInputChange} placeholder="Leave a comment here" style={{ height: "100px" }}></textarea>
                        <label className="text-dark" htmlFor="InputTextarea">Comments</label>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <button type="submit" className="fw-bold btn btn-light border border-dark border-4 rounded-0">Submit</button>
                </div>
            </form>
            <a href="mailto:brandon.lee.ruiz@gmail.com" id="sendEmail" hidden>mail</a>
        </div>
    )
}
