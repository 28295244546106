export const data = {

    services: [{
        id: 'service1',
        active: false,
        title: 'Deep Clean',
        description: 'Deep cleaning can be a cost effective way to maintain any commercial space. Deep cleaning is a more intensive, detailed service that includes areas and tasks not completed during basic daily or weekly cleaning. It can be used to target problem areas or to achieve a higher level of overall cleanliness for your business.',
        menuBackgroundColor: '#87C19A',
        img: '3_DeepClean',
    },
    {
        id: 'service2',
        active: false,
        title: 'Office Cleaning',
        description: 'A quality cleaning service can optimize your workspace. A clean and sanitary office environment provides a more positive experience for employees and clients. Let our services help your company present a more polished appearance.',
        menuBackgroundColor: '#4B6B56',
        img: '4_Office_Cleaning',
    },
    {
        id: 'service3',
        active: false,
        title: 'Warehouses',
        description: "A clean warehouse isn't just for appearances, it can limit safety risks for employees and improve operations. Let us create an individualized service plan to address the needs unique to your space and help to keep your business running safely and smoothly.",
        menuBackgroundColor: '#92D1A7',
        img: '5_Warehouse_Cleaning',
    },
    {
        id: 'service4',
        active: false,
        title: 'Parking Lots',
        description: 'Your parking lot is often the first and last thing new and returning clients see when visiting your business. Leave a lasting impression with our parking lot cleaning services. We can help you maintain a professional appearance and boost curb appeal with a well maintained lot.',
        menuBackgroundColor: '#03ec90',
        img: '6_Parking_Lots_Cleaning',
    },
    {
        id: 'service5',
        active: false,
        title: 'Supermarkets',
        description: 'Cleanliness directly impacts customer loyalty in the grocery industry and is the number two reason shoppers use when choosing their primary supermarket. Provide the best quality shopping experience for your clients with a consistently clean and safe environment with our supermarket cleaning service.',
        menuBackgroundColor: '#4c807e',
        img: '7_Supermarket_Cleaning',
    },
    {
        id: 'service6',
        active: false,
        title: 'Solar Panel Cleaning',
        description: 'Solar panel installation positioning, geographic location and environmental pollutants are all factors that can determine how often your system requires cleaning. Keep your panels operating at optimal effectiveness with our solar panel cleaning service.',
        menuBackgroundColor: '#9eecdf',
        img: '8_SolarPanelCleaning'
    },
    {
        id: 'service7',
        active: false,
        title: 'Sidewalk Cleaning',
        description: 'Our sidewalk cleaning service removes stains, built up dirt and debris and creates a safer, cleaner more inviting walkway to your business. Regular maintenance can also help prevent visitors from tracking in substances that may be damaging to carpets and flooring inside your business.',
        menuBackgroundColor: '#02abe4',
        img: '9_Sidewalk_Cleaning',
    },
    {
        id: 'service8',
        active: false,
        title: 'Exterior Building',
        description: "Your company's image can influence clients even before they walk through the front door. A consistently clean, well maintained exterior can have a positive impact on your business.  Our exterior cleaning service offers a variety of options that will have your brand in good hands.",
        menuBackgroundColor: '#6ebad1',
        img: '10_Exterior_building_cleaning',
    },
    {
        id: 'service9',
        active: false,
        title: 'Container Unloading',
        menuBackgroundColor: '#A4EBBC',
        description: 'Our experienced team provides flexible service plans at a great price for manual container unloading. Keep your business running safely and efficiently with our quick, professional unloading services.',
        img: '11_Container_Unloading',
    }],

    cardInfo: {
        title: '',
        description: '',
        img: ''
    },

    works: [
        {
            id: 'work1',
            title: 'Deep Clean',
            description: 'description1',
            miniDesc: 'Deep Clean',
            img: 'Works-01',
        },
        {
            id: 'work2',
            title: 'Offices',
            description: 'description1',
            miniDesc: 'Office Cleaning',
            img: 'Works-02',
        },
        {
            id: 'work3',
            title: 'Warehouses',
            description: 'description1',
            miniDesc: 'Warehouse Cleaning',
            img: 'Works-03',
        },
        {
            id: 'work4',
            title: 'House',
            description: 'description1',
            miniDesc: 'House Cleaning',
            img: 'Works-04',
        },
        {
            id: 'work5',
            title: ' Exterior Building',
            description: 'description1',
            miniDesc: 'Exterior Building Cleaning',
            img: 'Works-05',
        },
        {
            id: 'work6',
            title: 'Solar Panel',
            description: 'description1',
            miniDesc: 'Solar Panel Cleaning',
            img: 'Works-11',
        },
        {
            id: 'work7',
            title: 'Sidewalk',
            description: 'description1',
            miniDesc: 'Sidewalk Cleaning',
            img: 'Works-07',
        },
        {
            id: 'work8',
            title: 'Exterior Building',
            description: 'description1',
            miniDesc: 'Exterior Building Cleaning',
            img: 'Works-08',
        },
        {
            id: 'work9',
            title: 'Container Unloading',
            description: 'description1',
            miniDesc: 'Container Unloading',
            img: 'Works-12',
        },
        {
            id: 'work10',
            title: 'Exterior Building',
            description: 'description1',
            miniDesc: 'Exterior Building Cleaning',
            img: 'Works-10',
        },
    ]

};