import React, { useContext } from 'react';
import { DataContext } from '../../../data/DataContext';
import { types } from '../../../types/types';

export const MenuItem = ({ id, title, active, menuBackgroundColor }) => {

    const { dispatch } = useContext(DataContext);

    const handleClicMenu = (serviceId) => {
        dispatch({
            type: types.click,
            payload: serviceId,
        });
    };

    return (
        <div className="border border-dark border-3 pointer text-center menuHover" style={{ backgroundColor: menuBackgroundColor }} onClick={() => handleClicMenu(id)}>
            <span className={active ? "fs-2 fw-light fw-bold text-white fst-italic me-5" : "fs-2 fw-light fw-bold"} >{title}</span>
        </div>
    )
}
