import React from 'react';

export const InfoComponent = () => {
    return (
        <div className="text-center">
            <h1 className="display-5 fw-bold text-shadow">GET IN TOUCH!</h1>
            <div className="my-4">
                <p className="fw-bold text-shadow">TEL: (437) 771-7885</p>
            </div>
            <div className="mb-4">
                <p className="fw-bold text-shadow">EMAIL: cleaningags@gmail.com</p>
            </div>
            <div className="mb-4">
                <p className="fw-bold text-shadow">ADDRESS:  54 Valdor Dr, Scarborough, M1V 1R4</p>
            </div>
            <div className="mb-4">
                <p className="fw-bold text-shadow">CONTACT A CLEANAGS AGENT:</p>
            </div>
            <div className="mb-4">
                <p className="fw-bold text-shadow">TEL:  (437) 771-7885</p>
            </div>
            <div className="mb-4">
                <p className="fw-bold text-shadow">EMAIL:  cleaningags@gmail.com</p>
            </div>
            <div className="w-100">
                <div className="mt-5 d-flex justify-content-evenly">
                    <a href="https://api.whatsapp.com/send?phone=4377717885" target="_blank" rel="noopener noreferrer"><img src="./assets/icons/Icono_whatsapp.png" className="img-100" alt="whatsapp" /></a>
                    <a href="https://www.facebook.com/CleanAgs-110148088102061" target="_blank" rel="noopener noreferrer"><img src="./assets/icons/Icono_facebook.png" className="img-100" alt="facebook" /></a>
                    <a href="https://instagram.com/clean_ags/" target="_blank" rel="noopener noreferrer"><img src="./assets/icons/Icono_instagram.png" className="img-100" alt="instagram" /></a>
                </div>
            </div>
        </div>
    )
}
