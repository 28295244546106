import React, { useContext } from 'react';
import { DataContext } from '../../../data/DataContext';

export const Card = () => {

    const { data:{ cardInfo:{title, description, img} } } = useContext(DataContext);

    return (
        <div className="card rounded-0 border border-dark border-5 bg-green">
            <div className="row w-100">
                <div className="col-10 mx-auto mt-3">
                    <img src={`./assets/images/${img}.webp`}  className="card-img-top img-fluid img-thumbnail border border-dark border-0" style={{ maxHeight: "500px" }} alt="Logo" />
                </div>
                <div className="col-10 mx-auto">
                    <div className="card-body">
                        <h1 className='card-title text-center fw-bold fs-1 text-white'>{title}</h1>
                        <hr className="line-white" />
                        <p className='card-text text-center fw-bold fs-5 text-white'>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
