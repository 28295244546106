import React, { useContext, useEffect } from 'react';
import { DataContext } from '../../../data/DataContext';
import { types } from '../../../types/types';
import { Card } from './Card';
import { ServicesMenuList } from './ServicesMenuList';

export const ServicesScreen = () => {

    const { data: { services }, dispatch } = useContext(DataContext);

    useEffect(() => {
        dispatch({
            type: types.random, 
        });
        return () => {
        }
    }, [dispatch])

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 text-center my-4">
                    <h1 className="fw-bold  text-gray-cyan text-shadow display-3">Services</h1>
                    <hr className="line bg-dark" />
                </div>
                <div className="col-12 mb-5">
                    <div className="row">
                        <div className="col-12 col-md-4 px-0 d-flex align-items-center">
                            <div className="w-100">
                                <ServicesMenuList services={services} />
                            </div>
                        </div>
                        <div className="col-12 col-md-8 px-0">
                            <Card />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
