import React from 'react';
import { FormComponent } from './FormComponent';
import { InfoComponent } from './InfoComponent';

export const ContactScreen = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 text-center my-4 bg-white rounded rounded-pill">
                    <h1 className="display-3 fw-bold  text-gray-cyan  text-shadow my-0 py-0">Contact</h1>
                    <hr />
                    <h5>Give us a call today or fill out the form below to receive your personalized quote. We will schedule an on-site visit to ensure we provide an accurate estimate. We are confident we can offer a service plan to suit your company's needs, while providing exceptional service at a great price.</h5>
                </div>
                <div className="col-12 mb-5">
                    <div className="container border border-dark border-5 bg-comic text-white">
                        <div className="row py-5">
                            <div className="col-12 col-sm-6">
                                <FormComponent />
                            </div>
                            <div className="col-12 col-sm-6 mt-5 mt-sm-0">
                                <InfoComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
