import React from 'react';
import { Facebook, Instagram } from 'react-feather';

export const Footer = () => {
    return (
        <footer className="bg-white border-top border-3 border-dark py-3">
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                        <span className="fw-bold mx-3 text-gray-cyan">TERMS</span>&nbsp;
                        <span className="fw-bold mx-3 text-gray-cyan">PRIVACITY POLICY</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <a href="https://instagram.com/clean_ags/" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-dark" aria-label='instagram link'><Instagram className="mx-3" /></a>
                        <a href="https://www.facebook.com/CleanAgs-110148088102061" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-dark" aria-label='facebook-link'><Facebook className="mx-3 border border-3 border-dark rounded" /></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
