export const returnServicesServiceActiveCardInfo = (lastState, serviceId) => {
    const serviceSelected = lastState.services.find(service => service.id === serviceId);
    const arrayServices = lastState.services.map((service) => {
        if (service.id === serviceId) {
            if (service.active) {
                return service;
            } else {
                return {
                    ...service,
                    active: !service.active
                };
            }
        } else {
            if (service.active) {
                return { ...service, active: !service.active };
            } else {
                return service;
            }
        }
    });

    return {
        services: arrayServices,
        cardInfo: {
            title: serviceSelected.title,
            description: serviceSelected.description,
            img: serviceSelected.img
        }
    }
};

export const returnServicesRandomServiceActiveCardInfo = (lastState) => {
    const serviceSelected = lastState.services[Math.floor(Math.random() * lastState.services.length)]; //return index of array
    const arrayServices = lastState.services.map((service) => {
        if (service.id === serviceSelected.id) {
            return {
                ...service,
                active: !service.active
            };
        } else {
            return service;
        }
    });

    return {
        services: arrayServices,
        cardInfo: {
            title: serviceSelected.title,
            description: serviceSelected.description,
            img: serviceSelected.img
        }
    }
};